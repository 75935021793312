/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AttachmentConfigurationViewModel } from '../models/AttachmentConfigurationViewModel';
import type { AttestationProgressViewModel } from '../models/AttestationProgressViewModel';
import type { BlobDocumentUIViewModel } from '../models/BlobDocumentUIViewModel';
import type { OtterServiceReviewViewModel } from '../models/OtterServiceReviewViewModel';
import type { PacketDeliveryNotification } from '../models/PacketDeliveryNotification';
import type { ServiceReview } from '../models/ServiceReview';
import type { StatusCheckViewModel } from '../models/StatusCheckViewModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AuthAiService {

    /**
     * @param requestBody 
     * @returns any OK
     * @throws ApiError
     */
    public static statusCheckResult(
requestBody: StatusCheckViewModel,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/AuthAi/StatusCheckResult',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * A delivery notification is sent from Cheetah to Otter when the payer acknowledges the documents
     * @param requestBody An input for delivery notification that contains document ids
     * @returns any OK
     * @throws ApiError
     */
    public static cheetahInbound(
requestBody: PacketDeliveryNotification,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/AuthAi/CheetahInbound',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param formData 
     * @returns BlobDocumentUIViewModel OK
     * @throws ApiError
     */
    public static uploadDocumentToSubmitted(
formData?: {
File: Blob;
DashboardId: string;
},
): CancelablePromise<Array<BlobDocumentUIViewModel>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/AuthAi/UploadDocumentToSubmitted',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param requestBody 
     * @returns AttachmentConfigurationViewModel OK
     * @throws ApiError
     */
    public static getAttachmentValidationConfiguration(
requestBody?: ServiceReview,
): CancelablePromise<AttachmentConfigurationViewModel> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/AuthAi/GetAttachmentValidationConfiguration',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * Void an auth from AuthAi
     * @param requestBody The Service Review to void
     * @returns ServiceReview OK
     * @throws ApiError
     */
    public static voidAuthAiAuthorization(
requestBody: ServiceReview,
): CancelablePromise<ServiceReview> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/AuthAi/VoidAuthAiAuthorization',
            body: requestBody,
            mediaType: 'application/json-patch+json',
            errors: {
                400: `Bad Request`,
            },
        });
    }

    /**
     * Run a real time status check (inquiry) on a service review
     * @param requestBody The Service Review to run the status check
     * @returns ServiceReview OK
     * @throws ApiError
     */
    public static statusCheckRealTime(
requestBody: ServiceReview,
): CancelablePromise<ServiceReview> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/AuthAi/StatusCheckRealTime',
            body: requestBody,
            mediaType: 'application/json-patch+json',
            errors: {
                400: `Bad Request`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns OtterServiceReviewViewModel OK
     * @throws ApiError
     */
    public static isAttestationComplete(
requestBody: OtterServiceReviewViewModel,
): CancelablePromise<OtterServiceReviewViewModel> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/AuthAi/IsAttestationComplete',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param orgId 
     * @param paRequestId 
     * @param workflowId 
     * @returns AttestationProgressViewModel OK
     * @throws ApiError
     */
    public static getAttestationProgress(
orgId: string,
paRequestId?: number,
workflowId?: number,
): CancelablePromise<AttestationProgressViewModel> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/AuthAi/GetAttestationProgress',
            query: {
                'paRequestId': paRequestId,
                'orgId': orgId,
                'workflowId': workflowId,
            },
        });
    }

    /**
     * @param workflowId 
     * @param organizationId 
     * @returns string OK
     * @throws ApiError
     */
    public static getAttestationToken(
workflowId: number,
organizationId: string,
): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/AuthAi/GetAttestationToken',
            query: {
                'workflowId': workflowId,
                'organizationId': organizationId,
            },
        });
    }

}
