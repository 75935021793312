import type { BlockItemConfig } from "@/components/blockConfigs/_Types";

export default [
  {
    type: 'InputSelect',
    label: 'Modifier',
    prop: 'modifierCode1'
  },
  {
    type: 'InputSearch',
    label: 'NDC Code',
    prop: 'description'
  },
  {
    type: 'InputDate',
    label: 'From Date',
    prop: 'fromDate'
  },
  {
    type: 'InputDate',
    label: 'To Date',
    prop: 'toDate'
  },
  {
    type: 'InputSelect',
    label: 'Sub Type',
    prop: 'subType'
  },
  {
    type: 'InputSelect',
    label: 'Quantity Type',
    prop: 'serviceQuantityTypeCode'
  },
  {
    type: 'InputText',
    inputType: 'number',
    label: 'Quantity',
    prop: 'serviceQuantity'
  },
  {
    type: 'InputSelect',
    label: 'Quantity Type',
    prop: 'quantityTypeCode'
  },
  {
    type: 'InputText',
    inputType: 'number',
    label: 'Quantity',
    prop: 'quantity'
  },
  {
    type: 'InputSelect',
    label: 'Time Period Quantity Qualifier',
    prop: 'timePeriodQuantityQualifier'
  },
  {
    type: 'InputText',
    inputType: 'number',
    label: 'Time Period Quantity',
    prop: 'timePeriodQuantity'
  },
  {
    type: 'InputSelect',
    label: 'Timeframe Frequency Qualifier',
    prop: 'timeframeFrequencyQualifier'
  },
  {
    type: 'InputText',
    inputType: 'number',
    label: 'Timeframe Frequency',
    prop: 'timeframeFrequency'
  },
  {
    type: 'InputText',
    inputType: 'number',
    label: 'Requested Number of Treatments',
    prop: 'requestedNumberOfTreatments'
  },
  {
    type: 'InputSelect',
    label: '',
    prop: 'perRequest',
  },
  {
    type: 'InputText',
    label: 'Patient Weight',
    prop: 'patientWeight'
  },
  {
    type: 'InputSelect',
    label: 'Weight Units',
    prop: 'weightUnits'
  },
  {
    type: 'InputText',
    label: 'Route',
    prop: 'route'
  },
  {
    type: 'InputText',
    label: 'Procedure Code Description',
    prop: 'unlistedCodeDescription'
  },
  {
    type: 'InputText',
    label: 'Dose',
    prop: 'dose'
  },
  {
    type: 'InputText',
    label: 'Drug Name',
    prop: 'drugName'
  },
  {
    type: 'InputText',
    inputType: 'currency',
    label: 'DME Charge Info/MSRP',
    prop: 'charge',
  },
  {
    type: 'InputSelect',
    label: 'Procedure Service Type',
    prop: 'serviceTypeCode',
  },
  // {
  //   type: 'InputToggleBox',
  //   label: 'Federal Employee Program',
  //   prop: 'supplementalInformation.isFEPMember'
  // },
] as BlockItemConfig[]